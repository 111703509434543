import {
  AbsoluteCenter,
  AspectRatio,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  ModalBody,
  Spacer,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react"
import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import "../styles/custom.css"
import { useTranslation, Link, useI18next } from "gatsby-plugin-react-i18next"
import { US, TR, DE, IT } from "country-flag-icons/react/3x2"
import { AiOutlineWhatsApp } from "@react-icons/all-files/ai/AiOutlineWhatsApp"
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram"
// import { EmailIcon, PhoneIcon } from "@chakra-ui/icons"
import { MdPerson } from "@react-icons/all-files/md/MdPerson"
import { MdEmail } from "@react-icons/all-files/md/MdEmail"
import { MdPhoneIphone } from "@react-icons/all-files/md/MdPhoneIphone"
import { MdSend } from "@react-icons/all-files/md/MdSend"
import { Field, Form, Formik } from "formik"
import { SEO } from "../components/seo"

export default function Home({ data }) {
  const { t } = useTranslation()
  const { languages, originalPath, language } = useI18next()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useDisclosure()

  const defaultBannerText = {
    base: "sm",
    sm: "md",
    md: "xl",
    lg: "2xl",
    xl: "3xl",
  }
  const defaultPadding = {
    base: 12,
    lg: 16,
    xl: 32,
  }
  const defaultOpacity = {
    base: "0.5",
    lg: "1",
  }
  const defaultDisplay = {
    base: "none",
    lg: "flex",
  }
  const defaultHeading = {
    base: "2xl",
    sm: "3xl",
    lg: "4xl",
  }
  const defaultText = {
    base: "md",
    sm: "lg",
    lg: "xl",
  }

  // #region SECTION: Funcs
  function validatePhone(value) {
    let error
    let regex = /^\d+$/
    if (value && !value.match(regex)) {
      error = "Phone number must only be numeric"
    }
    return error
  }
  const cleanForm = () => {
    document.getElementById("contactForm").reset()
    document.getElementById("name").value = null
    document.getElementById("email").value = null
    document.getElementById("phone").value = null
    document.getElementById("treatment").selectedIndex = 0
    // setEmirates(null)
  }
  return (
    <>
      <Box>
        {/* TAG: NAV */}
        <nav>
          <Flex
            flex={1}
            pos="fixed"
            w="100%"
            px={4}
            py={2}
            h={14}
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            zIndex={5}
            className="glassmorphism"
          >
            <Flex w={48} maxW="40%" pos={"relative"} overflow={"hidden"}>
              <GatsbyLink to="#landing">
                <StaticImage
                  src="../images/logo-dark.png"
                  placeholder="none"
                  alt="AC Health Tourism official logo"
                />
              </GatsbyLink>
            </Flex>

            <Spacer />
            <a href="#contact">
              <Text fontWeight={"bold"} me={4}>
                {t("contact")}
              </Text>
            </a>

            <Box as={"button"} boxSize={8} onClick={onOpen}>
              {language == "en" ? (
                <US title="English" />
              ) : language == "tr" ? (
                <TR title="Türkçe" />
              ) : language == "de" ? (
                <DE title="Deutsch" />
              ) : (
                <IT title="Italiana" />
              )}
            </Box>

            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={{ base: "xs", md: "lg", lg: "xl" }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalBody p={8}>
                  <Center justifyContent="space-evenly" flexWrap={"wrap"}>
                    {languages.map(
                      lng =>
                        lng != language && (
                          <Link to={originalPath} language={lng}>
                            <Box
                              as={"button"}
                              w={{ base: 16, md: 32 }}
                              onClick={onOpen}
                              m={{ base: 2 }}
                              overflow="visible"
                              whiteSpace={"nowrap"}
                            >
                              {lng == "en" ? (
                                <>
                                  <US title="English" />
                                  <Text fontSize={defaultText} mt={4}>
                                    English
                                  </Text>
                                </>
                              ) : lng == "tr" ? (
                                <>
                                  <TR title="Türkçe" />
                                  <Text fontSize={defaultText} mt={4}>
                                    Türkçe
                                  </Text>
                                </>
                              ) : lng == "de" ? (
                                <>
                                  <DE title="Deutsch" />
                                  <Text fontSize={defaultText} mt={4}>
                                    Deutsch
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <IT title="Italiana" />
                                  <Text fontSize={defaultText} mt={4}>
                                    Italiana
                                  </Text>
                                </>
                              )}
                            </Box>
                          </Link>
                        )
                    )}
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </nav>
        {/* TAG: LANDING */}
        <Center
          id="landing"
          pt={{
            base: 14,
            lg: 0,
            xl: 0,
          }}
        >
          <AspectRatio w="100%" ratio={18 / 9}>
            <>
              <GatsbyImage
                objectFit="cover"
                objectPosition="top right"
                image={data.imageBanner.childImageSharp.gatsbyImageData}
                alt="Doctor content"
              />
              <Center position={"relative"}>
                <Center flex={1} p={[4, 8, 16, 32]}>
                  <Heading
                    as="h1"
                    size={defaultBannerText}
                    fontFamily="Quicksand"
                  >
                    {t("banner")}
                  </Heading>
                </Center>
                <Center flex={1} bg="yellow"></Center>
              </Center>
            </>
          </AspectRatio>
        </Center>
        {/* TAG: ABOUT */}
        <Center
          id="about-us"
          bg="frame1"
          position={"relative"}
          flexDirection={"column"}
          padding={defaultPadding}
          overflow={"hidden"}
        >
          <Box
            position={"absolute"}
            w={"100%"}
            minW="800px"
            maxW={"80em"}
            bottom={0}
            left={0}
            transform={"scale(0.35) translate(-110%, 110%)"}
            overflow={"hidden"}
            opacity={defaultOpacity}
          >
            <AspectRatio w="100%" ratio={1 / 1}>
              <StaticImage
                src="../images/medical-icon.png"
                placeholder="none"
                alt="Medical icon"
              />
            </AspectRatio>
          </Box>

          <Center position={"relative"}>
            <Center flex={1} w="100%" display={defaultDisplay}></Center>
            <Flex flex={2} flexDir="column">
              <Heading as={"h2"} size={defaultHeading}>
                {t("title")}
              </Heading>
              <Text fontSize={defaultText}>{t("text")}</Text>
            </Flex>
          </Center>
        </Center>
        {/* TAG: SERVICES */}
        <Center>
          <AspectRatio id="services" w="100%" minH={"18em"} ratio={18 / 9}>
            <>
              <GatsbyImage
                objectFit="cover"
                objectPosition="top right"
                image={data.imageService.childImageSharp.gatsbyImageData}
              />
              <Flex
                background={{
                  base: "linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.5) 100%)",
                  sm: "linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0.4) 100%)",
                  md: "none",
                }}
              ></Flex>
              <Center position={"relative"} padding={defaultPadding}>
                <Center flex={1}>
                  <Flex flexDir="column">
                    <Heading as="h2" size={defaultHeading}>
                      {t("services-title")}
                    </Heading>
                    <pre>
                      <Text
                        as="h3"
                        fontSize={defaultText}
                        fontFamily="Quicksand"
                      >
                        {t("services-text")}
                      </Text>
                    </pre>
                  </Flex>
                </Center>
                <Center flex={2}></Center>
              </Center>
            </>
          </AspectRatio>
        </Center>
        {/* TAG: CONTACT */}
        <Center
          id="contact"
          bg="frame2"
          position={"relative"}
          flexDirection={"column"}
          padding={defaultPadding}
          minH={"40em"}
          overflow={"hidden"}
        >
          <Box
            position={"absolute"}
            w={"100%"}
            minW="800px"
            bottom={0}
            right={0}
            transform={"scale(0.35) translate(90%, 110%)"}
            opacity={defaultOpacity}
            overflow={"hidden"}
          >
            <AspectRatio w="100%" ratio={1 / 1}>
              <StaticImage
                src="../images/plane-icon.png"
                placeholder="none"
                alt="Plane icon"
              />
            </AspectRatio>
          </Box>
          <Center w="100%" position={"relative"}>
            <Flex flex={2} flexDir="column">
              <Heading
                as={"h2"}
                size={defaultHeading}
                mb={8}
                // alignSelf="center"
              >
                {t("contact-title")}
              </Heading>
              <>
                {/* TAG: FORM */}
                <Formik
                  initialValues={{}}
                  onSubmit={(values, actions) => {
                    // TODO: add timeout (signal,abort,controller)
                    console.log("ASD")
                    onOpenContactForm()
                    fetch("/api/mail", {
                      method: "POST",
                      headers: {
                        Accept: "application/json, text/plain, */*",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(values, null, 2),
                    })
                    // .then(
                    // (res) => {
                    // if (res.status == 200) {
                    // actions.setSubmitting(false);
                    // },
                    // )
                    // .catch((err) => {
                    //   actions.setSubmitting(false);
                    // });
                  }}
                >
                  {props => (
                    // NOTE: UI start here
                    <Form id="contactForm" style={{ width: "100%" }}>
                      <Flex w="100%" flexDir="column" alignSelf="center">
                        <SimpleGrid
                          columns={{
                            base: 1,
                            md: 2,
                          }}
                          spacing={4}
                        >
                          <Field name="name" w="100%">
                            {({ field, form }) => (
                              <FormControl
                                w="100%"
                                id="name"
                                isInvalid={
                                  form.errors.name && form.touched.name
                                }
                                isRequired
                              >
                                <FormLabel htmlFor="name">
                                  {t("name-surname")}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <Icon
                                        as={MdPerson}
                                        w={6}
                                        h={6}
                                        color="gray.300"
                                      />
                                    }
                                  />
                                  <Input
                                    {...field}
                                    id="name"
                                    bg="white"
                                    placeholder={t("name-surname")}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.name}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="email" w="100%">
                            {({ field, form }) => (
                              <FormControl
                                w="100%"
                                isInvalid={
                                  form.errors.email && form.touched.email
                                }
                                isRequired
                              >
                                <FormLabel htmlFor="email">
                                  {t("email")}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <Icon
                                        as={MdEmail}
                                        w={6}
                                        h={6}
                                        color="gray.300"
                                      />
                                    }
                                  />
                                  <Input
                                    {...field}
                                    id="email"
                                    type="email"
                                    bg="white"
                                    placeholder={t("email-hint")}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.email}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="phone" validate={validatePhone} w="100%">
                            {({ field, form }) => (
                              <FormControl
                                w="100%"
                                id="phone"
                                isInvalid={
                                  form.errors.phone && form.touched.phone
                                }
                                isRequired
                              >
                                <FormLabel htmlFor="phone">
                                  {t("phone")}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <Icon
                                        as={MdPhoneIphone}
                                        w={6}
                                        h={6}
                                        color="gray.300"
                                      />
                                    }
                                  />
                                  <Input
                                    {...field}
                                    id="phone"
                                    type="tel"
                                    bg="white"
                                    placeholder={t("phone-hint")}
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.phone}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                          <Field name="treatment" w="100%">
                            {({ field, form }) => (
                              <FormControl
                                w="100%"
                                isInvalid={
                                  form.errors.community &&
                                  form.touched.community
                                }
                                isRequired
                              >
                                <FormLabel htmlFor="treatment">
                                  {t("treatment")}
                                </FormLabel>
                                <InputGroup>
                                  <InputLeftElement pointerEvents="none" />
                                  <Select
                                    {...field}
                                    id="treatment"
                                    bg="white"
                                    placeholder={t("treatment-hint")}
                                  >
                                    <option>{t("treatment1")}</option>
                                    <option>{t("treatment2")}</option>
                                    <option>{t("treatment3")}</option>
                                    <option>{t("treatment4")}</option>
                                    <option>{t("treatment5")}</option>
                                  </Select>
                                </InputGroup>
                                <FormErrorMessage>
                                  {form.errors.treatment}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Button
                            mt={8}
                            colorScheme="dark"
                            _hover={{ background: "dark.400" }}
                            alignSelf="flex-start"
                            type="submit"
                          >
                            {t("submit")}
                            <Icon mx={2} as={MdSend} w={5} h={5} c />
                          </Button>
                        </SimpleGrid>
                      </Flex>
                    </Form>
                  )}
                </Formik>
                {/* TAG: FORM MODAL */}
                <Modal
                  isOpen={isOpenContactForm}
                  onClose={onCloseContactForm}
                  closeOnOverlayClick={false}
                  closeOnEsc={false}
                >
                  <ModalOverlay />
                  <ModalContent overflow="hidden" w="auto" maxWidth="80vw">
                    <Alert
                      id="_contactFormSucceed"
                      status="success"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      height="100%"
                    >
                      <AlertIcon boxSize="40px" mr={0} />
                      <AlertTitle mt={4} mb={1} fontSize="lg">
                        {t("contact-modal-title")}
                      </AlertTitle>
                      <AlertDescription maxWidth="sm" mb={4}>
                        {t("contact-modal-description")}
                      </AlertDescription>
                      <Button
                        size="sm"
                        colorScheme="green"
                        bg="green.500"
                        color="#C6F6D5"
                        _focus={{ boxShadow: "none" }}
                        onClick={() => {
                          onCloseContactForm()
                          cleanForm()
                        }}
                      >
                        {t("contact-modal-button")}
                      </Button>
                    </Alert>
                  </ModalContent>
                </Modal>
              </>
            </Flex>
            <Center flex={1} p={16} display={defaultDisplay}></Center>
          </Center>
        </Center>
        {/* TAG: FOOTER */}
        <Center
          id="#footer"
          bg="gray.900"
          position={"relative"}
          overflow={"hidden"}
        >
          <Flex
            position={"relative"}
            flex={1}
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Center flex={1} p={4}>
              <Flex maxW={64} pos={"relative"} overflow={"hidden"}>
                <StaticImage
                  src="../images/logo-footer.png"
                  placeholder="none"
                />
              </Flex>
            </Center>
            <Center flex={1.5} p={4} flexDir="column" fontSize="sm">
              <Text color="white">{t("copyright")}</Text>
              <Flex flexDir={"row"}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.privacypolicies.com/live/1346a39a-3d2f-4a18-b111-c338b3c184ce"
                >
                  <Text color="white">{t("privacy-policy")}</Text>
                </a>
                <Text color="white">&nbsp; | &nbsp;</Text>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.privacypolicies.com/live/53a6c769-86f6-478c-a2af-c55ac0c668ef"
                >
                  <Text color="white">{t("terms-and-conditions")}</Text>
                </a>
              </Flex>
            </Center>
            <Center flex={1} p={4}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/905423731925"
              >
                <Box as="button" color="white" me={2}>
                  <AiOutlineWhatsApp size="2.5rem" />
                </Box>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/ac_healthtourism/"
              >
                <Box as="button" color="white">
                  <AiOutlineInstagram size="2.5rem" />
                </Box>
              </a>
            </Center>
          </Flex>
        </Center>
      </Box>
    </>
  )
}

export function Head() {
  return <SEO />
}

export const query = graphql`
  query ($language: String!) {
    imageBanner: file(relativePath: { eq: "doctor.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    imageService: file(relativePath: { eq: "hospital-staff.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
